<template>
  <!--  收货地址-->
  <div style="background:#F6F6F6;padding:0.32rem ">
    <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        :add-button-text="type"
        @select="select"
    />
    <div class="orderBackground" v-show="list.length==0">
      <img :src="require('../../images/noAddress.png')"/>
      <p>暂无地址数据</p>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant';
import leaseApi from "../../../utils/leaseApi";

export default {
  name: "deliveryAddress",
  data() {
    return {
      type: "",
      components: {},
      chosenAddressId: '1',
      disabledList: [],
      serviceOutletsValue: '',
      list: [],
      site: ""
    };
  },
  methods: {
    onAdd() {
      //确认收货地址
      if (this.type == "新增收货地址") {
        // this.$router.push({name:"myOrderDetails",params:{site:this.site}})
        this.$router.push({name: "theNewAddress", query: {type: "add"}})
      } else {
        // Toast('新增地址');
        this.$router.push({name: "theNewAddress", query: {type: "add"}})
      }
    },
    select(e) {
      // console.log(e)
      if (this.$route.query.type === '1') {
        let address = {
          siteName: e.name,
          siteAddress: e.address,
          sitePhone: e.phone,
          siteId: e.id
        }

        this.$store.commit('setOrderSite', address)
        this.$router.push({name: "myOrderDetails", params: {site: this.site}})
      }
      this.site = e
    },
    onEdit(item, index) {
      item.type = 'edit'
      this.$router.push({name: "theNewAddress", query: item})
    },

    async fetchData() {
      let _this = this;
      const {data} = await leaseApi.getSiteList();
      this.list = data.map(d => {
        let map = d
        map.name = d.recipients
        map.tel = d.phone
        map.address = d.province + d.city + d.county + d.detailedAddress
        map.isDefault = d.defaultStatus === 1
        if (d.defaultStatus === 1) {
          _this.chosenAddressId = d.id
        }
        return map;
      })
    },
    active() {
      if (this.$route.query.type == 1) {
        this.type = '新增收货地址'
      }
    }
  },

  created() {
    this.fetchData()
    this.active()
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-address-list {
  padding: 0rem;
  height: auto;
}


/deep/ .van-address-item {
  padding: 0rem;
}

/deep/ .van-address-item__name {
  margin-bottom: 0rem;
}

/deep/ .van-address-item__value {
  padding-bottom: 0.43rem;
  border-bottom: 0.03rem solid #EFEFEF;
  padding-top: 0.44rem;
  padding-left: 0.28rem;
}

/deep/ .van-address-item:not(:last-child) {
  margin-bottom: 0rem;
}

/deep/ .van-address-item__name {
  font-size: 0.44rem;
  font-family: xiaocheng;
  font-weight: 500;
  color: #333333;
}

/deep/ .van-address-item__tag {
  height: 0.44rem;
  background: #FF9966;
  border-radius: 0.11rem;
  font-size: 0.31rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.44rem;
  padding: 0.07rem 0.08rem;
}

/deep/ .van-address-item__address {
  font-size: 0.42rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #666666;
  margin-top: 0.35rem;
}

/deep/ .van-address-list__bottom {
  width: 100%;
  //height: 1.64rem;
  background: #FFFFFF;
  padding: 0.28rem 0.33rem;
}

/deep/ .van-button--danger {
  background: #FB884F;
  box-shadow: 0rem 0rem 0rem 0rem rgba(251, 140, 82, 0.13);
  border-radius: 1rem;
  border: 0rem;
}

.orderBackground {
  text-align: center;

  img {
    width: 6.94rem;
    margin-top: 50%;
  }

  p {
    font-size: 0.36rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #666666;
    margin-top: 0.56rem;
  }
}
</style>
